import Header from "../components/general/Header"
import Footer from "../components/general/Footer"

export default function Landing(){

    function handleSubmit(){

    }


    return(<>
    <div className="bg-[#E79441]">
    <Header />
    <div className="h-screen w-full pt-20 grid grid-cols-[55%_45%]">
        <div className="flex flex-col p-20">
            <p className="text-white font-bold text-5xl">Welcome to</p>
            <p className="text-white font-bold text-5xl">Samaveda Rakshanam</p>
            <p className="text-white font-semibold text-xl mt-10">Of the four Vedas, the Samaveda, which consisted of 1,000 shakas at one point currently, has only four available. It is our responsibility to ensure that these four shakas are protected and preserved for posterity.</p>
            <p className="text-white font-semibold text-lg mt-10">Sign Up to stay updated on our activities</p>
            <div className="mt-4 flex flex-row gap-x-2">
                <input type="email" className="text-slate-800 w-[60%] rounded-lg p-2 bg-[#EC7D0F] bg-opacity-60 font-semibold placeholder-white" placeholder="Enter your email" />
                <button className="text-white rounded-lg p-3 bg-slate-600 font-bold" onClick={handleSubmit}>Submit</button>
            </div>
        </div>
        <div className='ml-20'>
            <img className="w-[65%] h-auto pt-8 p-4" src={process.env.PUBLIC_URL+"/LandingImage.png"} alt={"Figures important to our mission"}/>
        </div>     
    </div>
    <Footer />
    </div>
    </>)
}