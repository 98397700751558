import { useState, useEffect } from 'react'

export default function Header() {
    const [top, setTop] = useState(true)

    // detect whether user has scrolled the page down by 10px
    const scrollHandler = () => {
        window.scrollY > 10 ? setTop(false) : setTop(true)
    }

    useEffect(() => {
        scrollHandler()
        window.addEventListener('scroll', scrollHandler)
        return () => window.removeEventListener('scroll', scrollHandler)
    }, [top])

    return (
        <header className={`fixed w-full transition duration-300 ease-in-out bg-[#EC7D0F] ${!top ? 'backdrop-blur-xl shadow-lg' : 'backdrop-blur-sm'}`}>
            <div className="max-w-7xl mx-auto px-5 sm:px-6">
                <div className="flex items-center justify-between h-16 md:h-20">
                    {/* Site branding */}
                    <div className="flex align-center">
                        <h1 onClick={() => { window.location = '/' }} className='mb-1 text-3xl font-bold text-white'>Samaveda Rakshanam</h1>
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden md:flex md:grow">
                        {/* Desktop sign in links */}
                        <ul className="flex grow justify-end flex-wrap items-center gap-x-8">
                            <li>
                                <a href="/" className="inline-block align-text-middle text-white hover:border-b-2 hover:border-white flex">
                                    <p className="mt-[1px] font-semibold">Home</p>
                                </a>
                            </li>
                            <li>
                                <a href="/" className="inline-block align-text-middle text-white hover:border-b-2 hover:border-whiteflex">
                                    <p className="mt-[1px] font-semibold">Resources</p>
                                </a>
                            </li>
                            <li>
                                <a href="/" className="inline-block align-text-middle text-white hover:border-b-2 hover:border-white flex">
                                    <p className="mt-[1px] font-semibold">Files</p>
                                </a>
                            </li>
                            <li>
                                <a href="/" className="inline-block align-text-middle text-white hover:border-b-2 hover:border-white flex">
                                    <p className="mt-[1px] font-semibold">About</p>
                                </a>
                            </li>
                            <li>
                                <a href="/" className="inline-block align-text-middle text-white hover:border-b-2 hover:border-white flex">
                                    <p className="mt-[1px] font-semibold">Contact</p>
                                </a>
                            </li>
                        </ul>

                    </nav>

                    {/* <MobileMenu /> */}

                </div>
            </div>
        </header>
    )
}
